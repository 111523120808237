import axios from 'axios'
import 'lodash'

export default {
  namespaced: true,
  state: {
    authToken: null,
    sim: null,
    sims: [],
    endpoints: [],
    endpointsnotused: [],
    endpoint: null,
  },
  getters: {
    authToken(state) {
      return state.authToken
    },
    sim(state) {
      return state.sim
    },
    sims(state) {
      return state.sims
    },
    endpoints(state) {
      return state.endpoints
    },
    endpointsnotused(state) {
      return state.endpointsnotused
    },
    endpoint(state) {
      return state.endpoint
    },
  },
  mutations: {
    SET_authToken(state, token) {
      state.authToken = token
    },
    SET_SIM(state, sim) {
      state.sim = sim
    },
    SET_ENDPOINT(state, endpoint) {
      state.endpoint = endpoint
    },
    SET_ENDPOINTS(state, endpoints) {
      state.endpoints = endpoints
    },
    SET_SIMS(state, sims) {
      state.sims = sims
    },
    SET_ENDPOINT_NOT_USED(state, end) {
      state.endpointsnotused = end
    },
  },
  actions: {
    getauthtoken({ dispatch }, data = {}) {
      return new Promise(() => {
        axios('https://cdn.emnify.net/api/v1/authenticate', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          data: data,
        })
          .then((response) => {
            dispatch('attempt', response.data.auth_token)
          })
          .catch((error) => console.log(error))
      })
    },

    getSims({ commit }, data = {}, params = '') {
      return new Promise(() => {
        axios(`https://cdn.emnify.net/api/v1/sim/${params}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + data.auth_token,
          },
          params: data.params,
        })
          .then((response) => {
            var tab = []
            commit('SET_SIMS', response.data)
            for (var i = 0; i < response.data.length; i++) {
              if (response.data[i].endpoint != null) {
                tab.push(response.data[i].endpoint)
              }
            }
            commit('SET_ENDPOINTS', tab)
          })
          .catch((error) => {
            console.log(error)
          })
      })
    },

    getSim({ commit }, data = {}) {
      return new Promise(() => {
        axios('https://cdn.emnify.net/api/v1/sim/' + data.sim_id, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + data.auth_token,
          },
        })
          .then((response) => {
            commit('SET_SIM', response.data)
          })
          .catch((error) => {
            commit('SET_SIM', null)
            console.log(error)
          })
      })
    },

    getEndpoints({ commit }, data = {}, params = '') {
      console.log(process.env.VUE_APP_EMNIFY_BASE_API)
      return new Promise((resolve, reject) => {
        axios(`${process.env.VUE_APP_EMNIFY_BASE_API}endpoint/${params}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + data.auth_token,
          },
          params: data.params,
        })
          .then((response) => {
            console.log(response)
            commit('SET_ENDPOINTS', response.data)
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getLocation(_, data = {}) {
      // https://cdn.emnify.net/api/v1/endpoint/11126934/location

      return new Promise((resolve, reject) => {
        axios(
          `${process.env.VUE_APP_EMNIFY_BASE_API}endpoint/${data.endpointId}/location`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + data.auth_token,
            },
            params: data.params,
          }
        )
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getEndpointByID({ commit }, data = {}) {
      return new Promise(() => {
        axios('https://cdn.emnify.net/api/v1/endpoint/' + data.endpoint_id, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + data.auth_token,
          },
        })
          .then((response) => {
            commit('SET_ENDPOINT', response.data)
          })
          .catch((error) => {
            commit('SET_ENDPOINT', null)
            console.log(error)
          })
      })
    },
    attempt({ commit }, data) {
      commit('SET_authToken', data)
    },
    setenp({ commit }, data) {
      commit('SET_ENDPOINT_NOT_USED', data)
    },
  },
}
