<template>
  <div v-loading.fullscreen.lock="false" class="register-container">

    <el-form ref="registerForm" class="register-form text-center" autocomplete="on" label-position="left"
      :model="ruleForm" :rules="rules">
      <span class="register-title">Création d'un compte</span>
      <p v-if="errors.length">
      <ul>
        <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
      </ul>
      </p>
      <el-form-item label="Prénom" prop="firstname">
        <el-input placeholder="Veuillez saisir votre prénom" v-model="ruleForm.firstname"></el-input>
      </el-form-item>
      <el-form-item label="Nom" prop="lastname">
        <el-input placeholder="Veuillez saisir votre nom" v-model="ruleForm.lastname"></el-input>
      </el-form-item>
      <el-form-item label="Nom d'utilisateur" prop="username">
        <el-input placeholder="Veuillez saisir votre nom d'utilisateur" v-model="ruleForm.username"
          prefix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input placeholder="Email" v-model="ruleForm.email" prefix-icon="el-icon-message"></el-input>
      </el-form-item>
      <el-form-item label="Mot de passe" prop="password">
        <el-input placeholder="Veuillez saisir votre mot de passe" v-model="ruleForm.password" prefix-icon="el-icon-lock"
          type="password"></el-input>
      </el-form-item>
      <el-form-item label="Mot de passe" prop="password">
        <el-input placeholder="Veuillez saisir votre mot de passe" v-model="ruleForm.cpassword" prefix-icon="el-icon-lock"
          type="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="RegisterUser()">Créer</el-button>
      </el-form-item>
      <div>
        <span>
          Vous avez déjà un compte?
        </span>
        <router-link to="login"><el-button type="text" size="default">s'identifier</el-button>
        </router-link>
      </div>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      errors: [],
      valid: true,
      ruleForm: {
        firstname: '',
        lastname: '',
        username: '',
        phone: '',
        email: '',
        password: '',
        cpassword: '',
      },
      rules: {
        firstname: [
          {
            required: true,
            message: 'Please input firstname',
            trigger: 'change',
          },
        ],
        lastname: [
          {
            required: true,
            message: 'Please input lastname',
            trigger: 'change',
          },
        ],
        username: [
          {
            required: true,
            message: 'Please input username',
            trigger: 'change',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input email',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: 'Please input password',
            trigger: 'change',
          },
        ],
      },
    }
  },
  mounted() {
    if (this.user) {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
    }),
  },
  methods: {
    RegisterUser() {
      if (this.ruleForm.email == '') {
        this.errors.push('Email is required')
        this.valid = false
      } else {
        this.valid = true
        this.errors = []
      }
      if (this.ruleForm.password == '') {
        this.errors.push('Password is required')
        this.valid = false
      } else {
        this.valid = true
        this.errors = []
      }
      if (this.valid) {
        axios
          .post('https://staging.atseeone.com')
          .catch((error) => console.log(error))
      }
    },
  },
}
</script>

<style>
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}

.register-title {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 20px;
}

.register-form {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80vh;
  max-width: 100%;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  .register-form {
    width: 80vw;
  }
}

@media only screen and (min-width: 1200px) {
  .register-form {
    width: 25vw;
  }
}
</style>
