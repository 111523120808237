import _ from 'lodash'
import jsonapi from '../../helpers/JsonParser'
import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'
import request from '@/utils/request'

export default {
  namespaced: true,
  state: {
    devices: [],
    devicesByStatus: [],
    params: {},
    insights: null,
    deviceResults: [],
    loading: true,
    resending: false,
    commands: [],
    config: [],
    memberships: [],
    info: null,
    device: null,
    metadata: null,
    filter: {
      name: null,
      organizationId: null,
      retailerId: null,
      q: null,
      make: null,
      model: null,
      status: null,
      motionState: null,
      page: 0,
    },
    selectedTab: 'details',
    devicesFilter: false,
    orgOptions: [],
    retailerOptions: [],
  },

  getters: {
    getField,
    orgOptions(state) {
      return state.orgOptions
    },
    retailerOptions(state) {
      return state.retailerOptions
    },
    selectedTab(state) {
      return state.selectedTab
    },
    devices(state) {
      return state.devices
    },
    memberships(state) {
      return state.memberships
    },
    device(state) {
      return state.device
    },
    metadata(state) {
      return state.metadata
    },
    info(state) {
      return state.info
    },
  },
  mutations: {
    updateField,
    SET_ORG_OPTIONS(state, data) {
      state.orgOptions = data
    },
    SET_RETAILERS_OPTIONS(state, data) {
      state.retailerOptions = data
    },
    SET_SETECTED_TAB(state, data) {
      state.selectedTab = data
    },
    SET_DEVICES(state, devices) {
      state.devices = devices
    },
    SET_PARAMS(state, value) {
      state.params = value
    },
    SET_INSIGHTS(state, value) {
      state.insights = value
    },
    SET_DEVICES_BY_STATUS(state, value) {
      state.devicesByStatus = value
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_COMMANDS(state, commands) {
      state.commands = commands
    },
    SET_DEVICE_RESULTS(state, data) {
      state.deviceResults = data
    },
    SET_CONFIG(state, data) {
      state.config = data
    },
    SET_DEVICE(state, data) {
      state.device = data
    },
    ADD_COMMAND(state, command) {
      state.commands.content.unshift(command)
    },
    REMOVE_COMMAND(state, commandId) {
      _.remove(state.commands.content, (item) => {
        return item.id === commandId
      })
    },
    SET_MEMBERSHIPS(state, memberships) {
      state.memberships = memberships
    },
    ADD_DEVICE(state, device) {
      state.devices.push(device)
    },
    UPDATE_DEVICE(state, data) {
      let index = state.devices.findIndex((item) => item.id == data.id)
      if (index != -1) Object.assign(state.devices[index], data)
    },
    REMOVE_DEVICE(state, id) {
      let index = state.devices.findIndex((item) => item.id == id)
      if (index != -1) state.devices.splice(index, 1)
    },
    SET_META(state, meta) {
      state.metadata = meta
    },
    SET_INFO(state, info) {
      state.info = info
    },

    setPosition(state, data) {
      state.position = data
    },
    setResending(state, data) {
      state.resending = data
    },
  },
  actions: {
    getInsightsApi({ commit }, { params = {}, headers = {}, data = {} }) {
      return request({
        url: `/devices/insights`,
        method: 'get',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('SET_INSIGHTS', response.data)
        })
        .catch((error) => console.log(error))
    },

    getByStatusApi({ commit }, { params = {}, headers = {}, data = {} }) {
      return request({
        url: `/devices/get-by-status`,
        method: 'get',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('SET_DEVICES_BY_STATUS', response.data)
        })
        .catch((error) => console.log(error))
    },

    getDevice({ commit }, { params = {}, headers = {} }) {
      let defaultParams = {
        sort: 'id,asc',
      }
      params = _.assignIn(defaultParams, params)
      return request({
        url: `/devices`,
        method: 'get',
        params,
        headers,
      })
        .then((response) => {
          commit('SET_DEVICES', response.data.content)
          commit('SET_META', {
            pageable: response.data.pageable,
            totalPages: response.data.totalPages,
            totalElements: response.data.totalElements,
          })
        })
        .catch((error) => console.log(error))
    },
    getResultsApi({ commit }, { params = {}, headers = {}, data = {} }) {
      let defaultParams = {
        sort: 'id,desc',
      }
      params = _.assignIn(defaultParams, params)
      return request({
        url: `/events`,
        method: 'get',
        params,
        headers,
        data,
      })
        .then((response) => {
          let data = jsonapi.parse(response.data).content
          commit('SET_DEVICE_RESULTS', data)
          return data
        })
        .catch((error) => console.log(error))
    },

    getCommandsApi(
      { commit },
      { deviceId = null, params = {}, headers = {}, data = {} }
    ) {
      let defaultParams = {
        sort: 'id,desc',
      }
      params = _.assignIn(defaultParams, params)
      return request({
        url: `/devices/${deviceId}/commands`,
        method: 'get',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('SET_COMMANDS', response.data)
        })
        .catch((error) => console.log(error))
    },

    addCommandApi(
      { commit },
      { deviceId = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/devices/${deviceId}/commands`,
        method: 'post',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('ADD_COMMAND', response.data)
        })
        .catch((error) => console.log(error))
    },

    deleteCommand(
      { commit },
      {
        deviceId = null,
        commandId = null,
        params = {},
        headers = {},
        data = {},
      }
    ) {
      return request({
        url: `/devices/${deviceId}/commands/${commandId}`,
        method: 'delete',
        params,
        headers,
        data,
      })
        .then(() => {
          commit('REMOVE_COMMAND', commandId)
        })
        .catch((error) => console.log(error))
    },

    deleteQueued( _ , 
      {
        deviceId = null,
        commandId = null,
        params = {},
        headers = {},
        data = {},
      }
    ) {
      return request({
        url: `/devices/${deviceId}/commands/${commandId}/remove-from-queue`,
        method: 'delete',
        params,
        headers,
        data,
      })
        .then(() => {
        })
        .catch((error) => console.log(error))
    },

    addDeviceApi({ commit }, { params = {}, headers = {}, data = {} }) {
      return request({
        url: `/devices`,
        method: 'post',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('ADD_DEVICE', response.data)
        })
        .catch((error) => console.log(error))
    },

    deleteDevice(
      { dispatch },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/devices/${id}`,
        method: 'delete',
        params,
        headers,
        data,
      })
        .then(() => {
          dispatch('getDevice', {})
        })
        .catch((error) => console.log(error))
    },

    updateDeviceApi(
      { commit },
      { id = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/devices/${id}`,
        method: 'put',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('UPDATE_DEVICE', response.data)
        })
        .catch((error) => console.log(error))
    },

    getPositions(_, params = {}) {
      if (params.startDate)
        params.startDate = moment(params.startDate).utc().format()
      if (params.endDate) params.endDate = moment(params.endDate).utc().format()

      return request({
        url: `/positions`,
        method: 'get',
        params,
      })
    },

    getLastPosition(
      { commit },
      { deviceId = null, params = {}, headers = {} }
    ) {
      return request({
        url: `/positions/getlast/${deviceId}`,
        method: 'get',
        params,
        headers,
      }).then((response) => {
        let data = jsonapi.parse(response.data)
        commit('setPosition', data)
        return data
      })
    },
    getDeviceApi(
      { commit },
      { deviceId = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/devices/${deviceId}`,
        method: 'get',
        params,
        headers,
        data,
      })
        .then((response) => {
          commit('UPDATE_DEVICE', response.data)
        })
        .catch((error) => console.log(error))
    },

    resendCommand(
      { commit },
      { deviceId = null, params = {}, headers = {}, data = {} }
    ) {
      return request({
        url: `/devices/${deviceId}/resend-command`,
        method: 'put',
        params,
        headers,
        data,
      })
        .then(() => {
          commit('setResending', false)
        })
        .catch((error) => console.log(error))
    },
  },
}
